import React, { useEffect } from "react";
import { genSaltSync, hashSync } from "bcryptjs";
import { checkEmailAddress, createEmailAddressForm } from "../utils";
import axios from "axios";
const formAPIURL = `${process.env.GATSBY_AI_SOLUTIONS_PAGE_FORM_URL}`;
const SERVER_URL = `${process.env.GATSBY_VIDURA_API_URL}`;

const ViduraChatbotWrapper = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        document.body.appendChild(script);
        // cleanup
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined" && window.TarkaChat) {
            window.TarkaChat.init({
                title: "Vidura",
                botName: "vidurabot",
                greeting:
                    "Hello, I am Vidura. I can help you answer any questions related to the Share Market or Mediclaim Policy.",
                themeColor: "#0000FF",
                selectorId: "chatbot",
                expand: true,
                preChatRenderer: (onClose) => getPreChatScreen(onClose),
                submitHandler: (message) => onMessageSubmit(message),
            });
        }
    }, []);

    const submitEmailAddress = async (emailAddress) => {
        await axios.post(
            formAPIURL,
            {
                emailAddress,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        );
    };

    function getPreChatScreen(onClose) {
        async function onClick() {
            const emailAddress = document.getElementById("ai-solutions-email-input").value;
            if (checkEmailAddress(emailAddress)) {
                await submitEmailAddress(emailAddress);
                localStorage.setItem("email-collected", true);
                await chatbotSetup();
            } else {
                errorDiv.innerText = "Please enter a valid email address.";
            }
        }
        const chatbotSetup = async () => {
            // Perform login action here, e.g., send data to server, etc.
            // For demonstration purposes, I'm just setting an item in localStorage
            const salt = genSaltSync();
            const passkey = hashSync("apple", salt);
            const sessionId = await startSession(passkey);
            const silos = await fetchSilos(passkey);
            const displaySilosNames = {
                "Upstox-Share-Market": "Share market",
                "Sedin-Mediclaim-Policy": "Mediclaim policy",
            };
            const filteredSilos = silos.filter((silo) => {
                return (
                    silo.name === "Upstox-Share-Market" || silo.name === "Sedin-Mediclaim-Policy"
                );
            });
            showSilos(filteredSilos, passkey, sessionId, displaySilosNames);
        };

        async function showSilos(silos, passkey, sessionId, displaySilosNames) {
            const prechatContainer = document.getElementById("prechat-container");
            prechatContainer.innerHTML = ""; // Remove existing content
            const silosContainer = document.createElement("div");
            silosContainer.classList.add("prechat-silos");
            // Create h4 element
            const h4 = document.createElement("h4");
            h4.textContent = "Choose one of the silos";
            silosContainer.appendChild(h4);
            // Create ul element
            const ul = document.createElement("ul");
            // Loop through silos and create li elements with buttons
            silos.forEach((item) => {
                const li = document.createElement("li");
                const button = document.createElement("button");
                button.textContent = displaySilosNames[item.name];
                button.onclick = () => selectSilo(item.name, passkey, sessionId);
                li.appendChild(button);
                ul.appendChild(li);
            });
            silosContainer.appendChild(ul);
            prechatContainer.appendChild(silosContainer);
        }
        function selectSilo(silo, passkey, sessionId) {
            // Handle selection of silo here
            console.log("Selected silo:", silo);
            localStorage.setItem(
                "vidura-bot-configs",
                JSON.stringify({
                    passkey: passkey,
                    sessionId: sessionId,
                    silo,
                })
            );
            onClose();
        }
        const container = document.createElement("div");
        container.id = "prechat-container";

        if (localStorage.getItem("email-collected")) {
            const startButton = document.createElement("button");
            startButton.id = "start-chat-action";
            startButton.textContent = "Click here to start chatting.";
            startButton.onclick = chatbotSetup;
            container.appendChild(startButton);
            return container;
        }
        const { form, errorDiv } = createEmailAddressForm(onClick);
        container.appendChild(form);
        return container;
    }
    function removeTrailingSlash(url) {
        // Regular expression to match a slash followed by the end of the string
        const regex = /\/$/;

        // Replace the matched pattern (trailing slash) with an empty string
        return url.replace(regex, "");
    }
    async function startSession(passkey) {
        try {
            const url = removeTrailingSlash(SERVER_URL) + "/api/start-session";
            const response = await fetch(url, {
                headers: { Authorization: "Bearer " + passkey },
            });
            const data = await response.json();
            return data?.sessionId;
        } catch (err) {
            console.error(err);
        }
    }
    async function fetchSilos(passkey) {
        try {
            const url = removeTrailingSlash(SERVER_URL) + "/api/silos";
            const response = await fetch(url, {
                headers: { Authorization: "Bearer " + passkey },
            });
            const data = await response.json();
            return data?.silos;
        } catch (err) {
            console.error(err);
        }
    }
    async function onMessageSubmit(message, silo, sessionId, passkey) {
        try {
            const configs = localStorage.getItem("vidura-bot-configs");
            const { passkey, sessionId, silo } = JSON.parse(configs);
            const url = removeTrailingSlash(SERVER_URL) + "/api/chat";
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + passkey,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ message, silo, sessionId }),
            });
            const data = await response.json();
            return data?.content;
        } catch (err) {
            console.error(err);
            return "Something went wrong!";
        }
    }
    return <div id="chatbot"></div>;
};
export default ViduraChatbotWrapper;
